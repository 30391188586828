import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Followers from "../components/followers-section"

const CreatorsPage = () => {
  return (
    <Layout>
      <Seo title="Seguidores" />
      <Followers />
    </Layout>
  )
}

export default CreatorsPage
